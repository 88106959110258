var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VueApexCharts", {
        ref: "semiDonut",
        attrs: { type: "donut", options: _vm.chartOptions, series: _vm.series }
      }),
      _c("span", { staticClass: "center-score" }, [_vm._v(_vm._s(_vm.point))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }